<template>
  <div class="card task-card"
       v-show="isShow"
  >
    <div class="task-card-header" @click="reverseExpand">
      <strong>{{task.description ? task.description : 'Описание не задано'}}</strong>
      <div class="task-card-header-data">
        <div>Начало обучения: {{getFormattedStartTime}}</div>
        <div v-if="task.end_time">Окончание обучения: {{getFormattedEndTime}}</div>
        <hr/>
        <div class="task-card-statusline">
          <div class="task-card-status">
            Статус: <strong :class="`status status-${task.status}`">{{task.status}}</strong>
          </div>
          <div
              v-if="task.status !== 'done' && task.status !== 'failed'"
              class="task-card-statusbuttons">
            <Button
                class="p-button-raised"
                label="Обновить статус"
                @click.stop="onUpdateStatus"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="task-card-body"
         v-show="isExpanded"
    >
      <div v-if="canShowRules">
        <hr/>
        <br/>
        <div class="task-card-rules">
          <div class="task-card-rule p-float-label">
            <InputMathNumber
                id="fisherCriterion"
                mode="decimal"
                :min="0"
                :max="0.1"
                v-model="filterParameters.max_fisher"
                v-tooltip.bottom="'Введите Критерий фишера'"
            />
            <label for="fisherCriterion">Критерий&nbsp;Фишера</label>
          </div>
          <div class="task-card-rule p-float-label">
            <InputMathNumber
                id="inProbabilityInput"
                mode="decimal"
                :min="0.5"
                :max="1"
                v-model="filterParameters.min_probability"
                v-tooltip.bottom="'Введите минимальную вероятность'"
            />
            <label for="inProbabilityInput">Минимальная&nbsp;вероятность</label>
          </div>
          <div class="task-card-rule">
            <br/><br/>
            <div class="p-float-label">
              <InputMathNumber
                  id="minObjectsInput"
                  mode="integer"
                  :min="1"
                  v-model="filterParameters.min_objects"
                  v-tooltip.bottom="'Введите минимальное количество объектов'"
              />
              <label for="minObjectsInput">Количество&nbsp;объектов</label>
            </div>
          </div>
          <div class="task-card-rule">
            <br/><br/>
            <Button
                label="Получить правила"
                @click="onGetRules"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "primevue/button";
import InputMathNumber from "@/components/InputMathNumber";

export default {
  components: {Button, InputMathNumber},
  props: {
    task: {
      type: Object,
      required: true,
    },
    filterName: {
      type: String,
      required: false,
      default: '',
    },
    expandTrigger: Boolean,
    collapseTrigger: Boolean,
  },
  emits: ['onUpdateStatusClick', 'onGetRulesClick'],
  data() {
    return {
      isExpanded: false,
      filterParameters: {
        min_probability: 0.7,
        max_fisher: 0.05,
        min_objects: 3,
      }
    }
  },
  computed: {
    isShow() {
      return !this.filterName || this.task.description.toLowerCase().includes(this.filterName.toLowerCase())
    },
    canShowRules() {
      return this.task.status === 'done'
    },
    getFormattedStartTime() {
      return this.task.start_time.toLocaleString()
    },
    getFormattedEndTime() {
      return this.task.end_time.toLocaleString()
    },
  },
  watch: {
    expandTrigger() {
      this.isExpanded = true
    },
    collapseTrigger() {
      this.isExpanded = false
    },
  },
  methods: {
    reverseExpand() {
      this.isExpanded = !this.isExpanded
    },
    onUpdateStatus() {
      this.$emit('onUpdateStatusClick', this.task.id)
    },
    onGetRules() {
      this.$emit('onGetRulesClick', this.task.id, this.filterParameters)
    },
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/layout/_variables.scss";

.task-card {
  color: $topbarLeftBgColor;
  position: relative;
  place-content: center;
  place-items: center;
  width: available;
  border-radius: 15px;
  letter-spacing: 0.05em;
  border: 1px solid $topbarLeftBgColor;
  text-decoration: none;
  margin-right: 10px;
  padding: 1rem 1.5rem;
  white-space: nowrap;
  outline: none;
  background: #fff;
  transition: all 0.22s;

  .task-card-header {
    text-transform: uppercase;
    font-weight: 700;

    .task-card-header-data {
      text-transform: none;
      font-weight: normal;
    }
  }

  .status {
    text-transform: uppercase;
  }
  .status-done {
    color: green;
  }
  .status-running {
    color: yellowgreen;
  }
  .status-queued {
    color: orange;
  }
  .status-failed {
    color: red;
  }
}

.task-card-header:hover {
  cursor: pointer;
  opacity: 0.8;
}

.task-card:active {
  box-shadow: inset 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.task-card-statusline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  .task-card-statusbuttons {
    display: flex;
    flex-direction: row;

    .p-button {
      margin-left: 10px;
    }
  }
}

.task-card-rules {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;

  .task-card-rule {
    flex: 50% 0 0;
  }
}

</style>