<template>
  <InputText
      type="text"
      v-model="modelNumber"
      :class="isError ? 'p-invalid' : ''"
  />
</template>

<script>
import InputText from "primevue/inputtext";

export default {
  components: {InputText},
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    mode: {
      type: String,
      default: 'decimal',
      validator(value) {
        return ['decimal', 'integer'].includes(value)
      }
    },
    min: {
      type: Number,
      default: null,
    },
    max: {
      type: Number,
      default: null,
    },
    // allowUnlimited: {
    //   type: Boolean,
    //   default: false,
    // },
    // errorMessage: {
    //   type: String,
    //   default: "Неверные данные",
    // },
  },
  data() {
    return {
      modelNumber: this.modelValue,
      isError: false,
    }
  },
  watch: {
    modelNumber(newValue, oldValue) {
      const val = Number(newValue)

      if (this.mode === 'decimal' && this.isDecimalNumber(val) ||
          this.mode === 'integer' && this.isIntegerNumber(val)) {
        const inLimits = this.isInLimits(val)
        this.setError(!inLimits)
        this.updateModelValue(val)
      } else {
        this.resetInputValue(oldValue)
      }
    }
  },
  methods: {
    isDecimalNumber(val) {
      return Number.isFinite(val)
    },
    isIntegerNumber(val) {
      return Number.isSafeInteger(val)
    },
    isInLimits(val) {
      return (this.min === null || val >= this.min) && (this.max === null || val <= this.max)
    },
    setError(val) {
      this.isError = val
    },
    updateModelValue(val) {
      this.$emit('update:modelValue', val)
    },
    resetInputValue(val) {
      this.modelNumber = val
    },
  }
}
</script>

<style scoped>
.p-invalid:focus {
  border-color: red;
  box-shadow: 0 0 5px red;
}
</style>