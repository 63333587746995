<template>
  <Dialog header="Правила успешно сохранены" v-model:visible="displayConfirmation" :style="{width: '350px'}" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-sitemap p-mr-3" style="font-size: 2rem" />
      <span>Показать их на странице просмотра результатов?</span>
    </div>
    <template #footer>
      <Button label="Остаться" icon="pi pi-times" @click="closeRulesConfirmation" class="p-button-text"/>
      <Button label="Показать" icon="pi pi-check" @click="openResultPage" class="p-button-text" autofocus />
    </template>
  </Dialog>

	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
        <div class="card-tools">
          <div class="card-filter">
            <div class="p-float-label">
              <InputText id="searchInput" v-model="filterName"/>
              <label for="searchInput">Фильтр</label>
            </div>
            <div
                class="filter-clear-button"
                v-if="filterName"
                @click="filterName = ''"
            >×</div>
          </div>
          <div class="tool-buttons">
            <Button
                class="p-button-raised tool-button"
                label="Развернуть всё"
                @click="expandTrigger = !expandTrigger"
            />
            <Button
                class="p-button-raised tool-button"
                label="Свернуть всё"
                @click="collapseTrigger = !collapseTrigger"
            />
          </div>
        </div>
        <br />
        <hr />
        <br />
				<TaskCard
            v-for="task in sortedTasks"
            :key="task.id"
            :task="task"
            :filter-name="filterName"
            :expand-trigger="expandTrigger"
            :collapse-trigger="collapseTrigger"
            @onUpdateStatusClick="onUpdateStatusClick"
            @onGetRulesClick="onGetRulesClick"
        />
			</div>
		</div>
	</div>
</template>

<script>
import {inject} from "vue";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import TaskCard from "@/components/TaskCard";
import {saveTextAsJson} from "@/utils/util";
import {routes} from "@/router";

export default {
  setup() {
    const tasks = inject('tasks')
    const transitRules = inject('transitRules')
    const updateTaskStatus = inject('updateTaskStatus')
    const hideSpinner = inject('hideSpinner')
    const showSpinner = inject('showSpinner')

    return {
      tasks,
      transitRules,
      updateTaskStatus,
      hideSpinner,
      showSpinner,
    }
  },
  components: {Button, Dialog, InputText, TaskCard},
  data() {
    return {
      filterName: '',
      displayConfirmation: false,
      rules: null,
      expandTrigger: false,
      collapseTrigger: false,
    }
  },
  computed: {
    sortedTasks() {
      return this.tasks.map(t => t).sort((t2, t1) => {
        return t1.start_time.getTime() - t2.start_time.getTime()
      })
    }
  },
  methods: {
    onUpdateStatusClick(id) {
      this.updateTaskStatus(id)
    },
    showRulesConfirmation() {
      this.displayConfirmation = true
    },
    closeRulesConfirmation() {
      this.rules = null
      this.displayConfirmation = false
    },
    openResultPage() {
      this.transitRules.value = JSON.parse(this.rules)

      this.closeRulesConfirmation()
      this.$router.push(routes[0].children[3].path)
    },
    async onGetRulesClick(id, filter) {
      const data = filter
      data.id = id
      console.log('sent data', data)
      try {
        this.showSpinner()

        const res = await this.$analytycCrud.get('/getRules', data)

        if (res && res.data && res.data.code === 0) {
          console.log('rules response', res.data)
          saveTextAsJson(res.data.rules, 'result_rules')
          this.rules = res.data.rules
          this.showRulesConfirmation()
        } else if (res?.data?.code === 1) {
          this.$toast.add({
            severity:'warn', summary: 'Правила не найдены', detail:'Попробуйте изменить запрос', life: 5000
          });
          console.warn('Правила не найдены')
        } else {
          throw new Error('rules not found: ' + res.data.msg)
        }
      } catch (e) {
        this.$toast.add({
          severity:'error', summary: 'Невозможно плоучить правила', detail:'Обратитесь в вашу службу поддержки', life: 5000
        });
        console.error('something went wrong', e)
      } finally {
        this.hideSpinner()
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "src/assets/layout/_variables.scss";

.card-tools {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .card-filter {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    .filter-clear-button{
      font-size: large;
      padding: 5px;
      margin-left: 5px;
    }

    .filter-clear-button:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  .tool-button {
    margin-left: 10px;
  }
}
</style>
