export function getFileExtension(fileName, withDot = false) {
    const pos = fileName.lastIndexOf('.')
    if (pos < 0 || pos >= fileName.length - 1) { return '' }
    return fileName.substr(pos + (withDot ? 0 : 1))
}


export function saveDataAsJson(data, fileName, unique = true) {
    const download = (content, fileName, contentType) => {
        const a = document.createElement("a");
        const file = new Blob([content], {type: contentType});
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    }
    download(JSON.stringify(data),
        `${fileName}${unique ? '_' + Date.now() : ''}.json`,
        'application/json');
}

export function saveTextAsJson(data, fileName, unique = true) {
    const download = (content, fileName, contentType) => {
        const a = document.createElement("a");
        const file = new Blob([content], {type: contentType});
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    }
    download(data,
        `${fileName}${unique ? '_' + Date.now() : ''}.json`,
        'application/json');
}


